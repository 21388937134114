import React, { useState, useContext } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import "./Modal.css";
import axios from "axios";
import SuccessAnimation from "../CommonComponents/SuccessAnimation";
import { ADD_STRATEGY_DETAILS, authToken } from "../../../Constant/Constants";
import { Info } from "@phosphor-icons/react";
import { StrategyContext } from "../../../Context/StrategyContext";

const AddStrategy = ({ show, onHide }) => {

  const { refetch } = useContext(StrategyContext);

  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const handleSubmit = async (values) => {
    try {
      const response = await axios.post(
        `${ADD_STRATEGY_DETAILS}`,
        {
          strategy: (values?.strategy || "").trim() || null,
          password: values?.password,
          symbol: values?.symbol,
          orderType: values?.ordertype,
          direction: values.direction,
          inverse: values?.inverse === "true",
          pyramid: values?.pyramid === "true",
        },
        {
          headers: authToken,
        }
      );
      console.log(response?.data);
      setSuccess("Strategy successfully added");
      setTimeout(() => {
        setSuccess(null);
        onHide();
        refetch();
      }, 3000);
    } catch (error) {
      console.error("Error adding client:", error);
      if (error?.response?.status === 400) {
        setError("Strategy already exists");
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
    }
    console.log(values, "Values");
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title className="clash-text">Add Strategy</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {error && (
            <div className="align-items-center d-flex error-message-container">
              <Info size={24} color="#ff0000" className="mx-1" />
              <p className="Plus-jakarta-text mt-3 text-danger">{error}</p>
            </div>
          )}

          {success ? (
            <>
              <SuccessAnimation />
              <p className="clash-text d-flex justify-content-center">
                {success}
              </p>
            </>
          ) : (
            <Formik
              initialValues={{
                strategy: "",
                password: "",
                symbol: "",
                ordertype: "Market",
                direction: "",
                inverse: "true",
                pyramid: "true",
              }}
              onSubmit={handleSubmit}
              validate={(values) => {
                const errors = {};
                if (!values?.strategy) {
                  errors.strategy = "Strategy Name is required";
                }
                if (!values?.password) {
                  errors.password = "Password is required";
                }
                if (!values?.direction) {
                  errors.direction = "Direction is required";
                }
                if (!values?.symbol) {
                  errors.symbol = "Symbol is required";
                }
                if (!values?.ordertype) {
                  errors.ordertype = "Order Type is required";
                }
                if (!values?.inverse) {
                  errors.inverse = "Inverse is required";
                }
                if (!values?.pyramid) {
                  errors.pyramid = "Pyramid is required";
                }
                return errors;
              }}
            >
              {({ values, errors, handleChange, handleSubmit, isValid }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="strategy">
                    <Form.Label>Strategy Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="strategy"
                      value={(values?.strategy || "").replace(/\s/g, "")}
                      onChange={handleChange}
                      className="custom-input"
                      required
                    />
                    {errors.strategy && (
                      <p className="text-danger">{errors.strategy}</p>
                    )}
                  </Form.Group>

                  <Form.Group controlId="password">
                    <Form.Label>Strategy password</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      value={values?.password}
                      onChange={handleChange}
                      className="custom-input"
                      required
                    />
                    {errors.password && (
                      <p className="text-danger">{errors.password}</p>
                    )}
                  </Form.Group>

                  <Form.Group controlId="symbol">
                    <Form.Label>Symbol</Form.Label>
                    <Form.Control
                      type="text"
                      name="symbol"
                      value={values?.symbol}
                      onChange={handleChange}
                      className="custom-input"
                      required
                    />
                    {errors.symbol && (
                      <p className="text-danger">{errors.symbol}</p>
                    )}
                  </Form.Group>

                  <Form.Group controlId="ordertype">
                    <Form.Label>Order Type</Form.Label>
                    <Form.Control
                      as="select"
                      name="ordertype"
                      value={values?.ordertype}
                      onChange={handleChange}
                      className="custom-input"
                      required
                    >
                      <option value="Limit">Limit</option>
                      <option value="Market">Market</option>
                    </Form.Control>
                    {errors.ordertype && (
                      <p className="text-danger">{errors.ordertype}</p>
                    )}
                  </Form.Group>

                  <Form.Group controlId="direction">
                    <Form.Label>Direction</Form.Label>
                    <div className="d-flex justify-content-between">
                      <Form.Check
                        type="radio"
                        label="Long / Short"
                        name="direction"
                        id="long_short"
                        value="long_short"
                        checked={values.direction === "long_short"}
                        onChange={handleChange}
                        className="custom-radio"
                      />
                      <Form.Check
                        type="radio"
                        label="Long / Only"
                        name="direction"
                        id="long_only"
                        value="long_only"
                        checked={values.direction === "long_only"}
                        onChange={handleChange}
                        className="custom-radio"
                      />
                      <Form.Check
                        type="radio"
                        label="Short / Only"
                        name="direction"
                        id="short_only"
                        value="short_only"
                        checked={values.direction === "short_only"}
                        onChange={handleChange}
                        className="custom-radio"
                      />
                    </div>
                    {errors.direction && (
                      <p className="text-danger">{errors.direction}</p>
                    )}
                  </Form.Group>

                  <Form.Group controlId="inverse">
                    <Form.Label>Inverse</Form.Label>
                    <Form.Control
                      as="select"
                      name="inverse"
                      value={values?.inverse}
                      onChange={handleChange}
                      className="custom-input"
                      required
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </Form.Control>
                    {errors.inverse && (
                      <p className="text-danger">{errors.inverse}</p>
                    )}
                  </Form.Group>

                  <Form.Group controlId="pyramid">
                    <Form.Label>Pyramid</Form.Label>
                    <Form.Control
                      as="select"
                      name="pyramid"
                      value={values?.pyramid}
                      onChange={handleChange}
                      className="custom-input"
                      required
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </Form.Control>
                    {errors.pyramid && (
                      <p className="text-danger">{errors.pyramid}</p>
                    )}
                  </Form.Group>

                  <div className="d-flex justify-content-between mt-3">
                    <Button className="py-2 cancle-button" onClick={onHide}>
                      Cancel
                    </Button>
                    <Button
                      variant="secondary"
                      type="submit"
                      className="px-4 py-2"
                      disabled={!isValid}
                    >
                      Add
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddStrategy;
