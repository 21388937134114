import React from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";

const EditClientModal = ({
  show,
  onHide,
  editData,
  setEditData,
  handleUpdateClient,
}) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Edit Client</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formClientId">
            <Form.Label>Client ID</Form.Label>
            <Form.Control
              type="text"
              className="custom-input"
              value={editData?.clientId}
              disabled
            />
          </Form.Group>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="tradable">
                <Form.Label>Tradable</Form.Label>
                <Form.Control
                  as="select"
                  className="custom-input"
                  value={editData?.tradeable.toString()}
                  onChange={(e) =>
                    setEditData({
                      ...editData,
                      tradeable: e.target.value === "true",
                    })
                  }
                >
                  <option value="true">Enable</option>
                  <option value="false">Disable</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="live">
                <Form.Label>Account Type</Form.Label>
                <Form.Control
                  as="select"
                  className="custom-input"
                  value={editData?.live.toString()}
                  onChange={(e) =>
                    setEditData({
                      ...editData,
                      live: e.target.value === "true",
                    })
                  }
                  disabled
                >
                  <option value="true">Live</option>
                  <option value="false">Demo</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <div className="d-flex justify-content-between mt-3">
          <Button className="py-2 cancle-button" onClick={() => onHide()}>
            Close
          </Button>
          <Button
            className="px-3 py-2 save-change-button"
            variant="secondary"
            onClick={handleUpdateClient}
          >
            Save Changes
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditClientModal;
