import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Button } from "react-bootstrap";
import { DownloadSimple, Plus } from "@phosphor-icons/react";

import Client from "./Tabs/Client";
import Strategy from "./Tabs/Strategy";
import Configuration from "./Tabs/Configration";
import WebhookLog from "./Tabs/WebhookLog";
import OrderLogs from "./Tabs/OrderLogs";

import AddClient from "./Modals/AddClient";
import AddStrategy from "./Modals/AddStrategy";
import AddConfiguration from "./Modals/AddConfigration";

import Header from "./Header/Header";
import "./Dashboard.css";

import axios from "axios";
import moment from "moment";
import { json2csv } from "json-2-csv";
import { saveAs } from "file-saver";
import {
  GET_ORDERLOGS_DETAILS,
  GET_WEBHOOKSLOGS_DETAILS,
  authToken,
} from "../../Constant/Constants";

const Dashboard = ({ handleLogout }) => {
  const [selectedTab, setSelectedTab] = useState(() => {
    return localStorage.getItem("selectedTab") || "clients";
  });
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const [showAddStrategyModal, setShowAddStrategyModal] = useState(false);
  const [showAddConfigModal, setShowAddConfigModal] = useState(false);

  useEffect(() => {
    localStorage.setItem("selectedTab", selectedTab);
  }, [selectedTab]);

  const handleSelect = (key) => {
    setSelectedTab(key);
  };

  const handleAddUser = () => {
    switch (selectedTab) {
      case "clients":
        setShowAddUserModal(true);
        break;
      case "strategy":
        setShowAddStrategyModal(true);
        break;
      case "configuration":
        setShowAddConfigModal(true);
        break;
      default:
    }
  };
  const getButtonLabel = () => {
    if (selectedTab === "webhookLog" || selectedTab === "orderLog") {
      return "Download CSV";
    }
    return "Add New";
  };
  const getButtonIcon = () => {
    if (selectedTab === "webhookLog" || selectedTab === "orderLog") {
      return <DownloadSimple size={21} color="#faf9fb" />;
    }
    return <Plus size={22} color="#faf9fb" className="mb-1" />;
  };

  const handleOrderLogCSVDownload = async () => {
    try {
      const response = await axios.get(GET_ORDERLOGS_DETAILS, {
        headers: authToken,
      });

      const data = response?.data?.map((item) => ({
        clientId: item.user_order_data?.clientId,
        strategy: item.strategy,
        quantity: item.user_order_data?.quantity,
        symbol: item.user_order_data?.symbol,
        timestamp: moment(item?.timestamp).format("YYYY-MM-DD HH:mm:ss"),
        action: item.user_order_data?.action,
        orderType: item.user_order_data?.orderType,
        price: item?.order_details?.PositionBase?.OpenPrice
          ? item?.order_details?.PositionBase?.OpenPrice
          : "-",
      }));
      const csvData = json2csv(data, {
        fields: [
          "clientId",
          "strategy",
          "orderQty",
          "symbol",
          "timestamp",
          "action",
          "orderType",
          "price",
        ],
      });

      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
      saveAs(blob, "order logs.csv");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleWebhooksLogsDownload = async () => {
    try {
      const response = await axios.get(GET_WEBHOOKSLOGS_DETAILS, {
        headers: authToken,
      });

      const data = response?.data?.map((item) => ({
        strategy: item?.user_data?.strategy,
        timestamp: moment(item?.timestamp).format("YYYY-MM-DD HH:mm:ss"),
        status: item?.status,
        action: item?.user_data?.action,
      }));

      const csvData = json2csv(data, {
        fields: ["strategy", "timestamp", "status", "action"],
      });

      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
      saveAs(blob, "webhook logs.csv");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <Header handleLogout={handleLogout} />
      <p className="clash-text admin-text px-md-4 px-3 py-3">
        Saxo ATS Dashboard
      </p>
      <div className="dashboard-tabs">
        <Tabs
          defaultActiveKey="client"
          id="responsive-tab-example"
          className="mb-3 px-0 px-md-2 custom-tabs"
          activeKey={selectedTab}
          onSelect={handleSelect}
        >
          <Tab eventKey="clients" title="Clients">
            <Client />
          </Tab>

          <Tab eventKey="strategy" title="Strategy">
            <Strategy />
          </Tab>
          <Tab eventKey="configuration" title="Configuration">
            <Configuration />
          </Tab>

          {/* <Tab eventKey="webhookLogs" title="Webhook Logs">
            <WebhookLog />
          </Tab> */}
          <Tab eventKey="orderLog" title="Order Logs">
            <OrderLogs />
          </Tab>
        </Tabs>
        <div className="align-items-end d-flex flex-column-reverse justify-content-end tab-buttons me-4 me-md-0">
          {selectedTab === "orderLog" || selectedTab === "webhookLogs" ? (
            <Button
              className="download-csv-button mx-md-4 mx-0 px-3 pb-1"
              onClick={
                selectedTab === "orderLog"
                  ? handleOrderLogCSVDownload
                  : handleWebhooksLogsDownload
              }
            >
              Download CSV <DownloadSimple size={21} color="#faf9fb" />
            </Button>
          ) : (
            <Button
              className="add-user-button mx-md-4 mx-0 px-3 pb-1"
              onClick={handleAddUser}
            >
              {getButtonIcon()} {getButtonLabel()}
            </Button>
          )}
        </div>
      </div>

      <AddClient
        show={showAddUserModal}
        onHide={() => setShowAddUserModal(false)}
      />
      <AddStrategy
        show={showAddStrategyModal}
        onHide={() => setShowAddStrategyModal(false)}
      />
      <AddConfiguration
        show={showAddConfigModal}
        onHide={() => setShowAddConfigModal(false)}
      />
    </>
  );
};

export default Dashboard;
