import React, { useState } from "react";
import { Formik } from "formik";
import { Container, Button, Form, Alert } from "react-bootstrap";
import "./Login.css";
import logo from "../../../Assets/logo.png";
import { useNavigate } from "react-router";
import axios from "axios"; 
import { ADD_LOGIN_DETAILS, authToken } from "../../../Constant/Constants";
import { Info } from "@phosphor-icons/react";

const LogIn = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(""); 

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post(
        `${ADD_LOGIN_DETAILS}`,
        {
          email: values.Email,
          password: values.password,
        },
        {
          headers: authToken,
        }
      );
      setIsLoggedIn(true); 
      navigate("/dashboard");
    } catch (error) {
      console.error("Error logging in:", error);
      if (error?.response && error?.response?.status === 400) {
        setError("Invalid email or password. Please try again.");
      } else {
        setError("An unexpected error occurred. Please try again later.");
      }
    }
  };

  return (
    <div className="center-box">
      <Container className="d-flex justify-content-center">
        <div className="p-3 p-md-4 px-0 row signup-box">
          <Formik
            initialValues={{
              Email: "",
              password: "",
            }}
            onSubmit={handleSubmit}
            validate={(values) => {
              const errors = {};
              if (!values?.Email) {
                errors.Email = "Email is required";
              }
              if (!values?.password) {
                errors.password = "Password is required";
              } else if (
                values.password.length < 8 ||
                !/(?=.*[!@#$%^&*])/.test(values.password)
              ) {
                errors.password =
                  "Password must be at least 8 characters long and contain at least one symbol";
              }
              return errors;
            }}
          >
            {({ values, handleChange, handleSubmit, isValid }) => (
              <Form onSubmit={handleSubmit}>
                <div className="text-center mb-3 mt-3">
                  <img src={logo} alt="logo" className="w-auto" />
                </div>
                {error && (
                  <div>
                    <Alert variant="danger">
                      {" "}
                      <Info size={22} color="#ff0000" className="mx-1 mb-1" />
                      {error}
                    </Alert>
                  </div>
                )}

                <Form.Group controlId="Email">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    name="Email"
                    value={values?.Email}
                    onChange={handleChange}
                    className="custom-input"
                    required
                  />
                </Form.Group>

                <Form.Group controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={values?.password}
                    onChange={handleChange}
                    className="custom-input"
                    required
                  />
                  {values.password &&
                    (values.password.length < 8 ||
                      !/(?=.*[!@#$%^&*])/.test(values.password)) && (
                      <Form.Text className="error-text">
                        Password must be at least 8 characters long and contain
                        at least one symbol
                      </Form.Text>
                    )}
                </Form.Group>

                <Button
                  variant="secondary"
                  type="submit"
                  className="mt-3 px-5 py-2 w-100 mb-2 login-button"
                  disabled={!isValid}
                >
                  Login
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  );
};

export default LogIn;
