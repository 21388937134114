import { Navbar, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import "../Dashboard.css";
import logo from "../../../Assets/logo.png";
import { SignOut, UserCircle } from "@phosphor-icons/react";

const Header = ({handleLogout}) => {
  const navigate = useNavigate(); // Access navigate function from React Router

  // const handleLogout = () => {
  //   // Perform logout operation here
  //   // For example, clear user session, redirect to login page, etc.
  //   // After logout, redirect to the login page
  //   navigate("/");
  // };

  return (
    <>
      <div className="header">
        <Navbar
          expand="lg"
          className="header-background"
          style={{ height: "60px" }}
        >
          <Navbar.Brand href="/dashboard" className="mx-4">
            <img
              src={logo}
              alt="Logo"
              className="d-none d-lg-block"
              style={{ maxHeight: "40px", marginRight: "10px" }}
            />
          </Navbar.Brand>

          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="ml-auto">
              <UserCircle size={30} color="#ffffff" className="mx-2" />
              <SignOut
                size={24}
                color="#ffffff"
                className="me-4 mt-1 pointer-class"
                onClick={handleLogout} // Add onClick event handler for logout
              />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  );
};

export default Header;

