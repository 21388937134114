import React, { useState,useContext } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import "./Modal.css";
import axios from "axios";
import SuccessAnimation from "../CommonComponents/SuccessAnimation";
import EditConfigrationModal from "./EditModals/EditConfigration";
import {
  ADD_CONFIGURATION_DETAILS,
  authToken,
} from "../../../Constant/Constants";
import { Info } from "@phosphor-icons/react";
import { ClientContext } from "../../../Context/ClientContext";
import { StrategyContext } from "../../../Context/StrategyContext";
import { StrategyConfigContext } from "../../../Context/StrategyConfigContext";

const AddConfigration = ({ show, onHide }) => {

  const { clients } = useContext(ClientContext);
  const { strategy } = useContext(StrategyContext);
  const { refetch } = useContext(StrategyConfigContext);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post(
        `${ADD_CONFIGURATION_DETAILS}`,
        {
          clientId: values?.clientId,
          strategy: values?.strategyname,
          stopLoss: parseFloat(values?.stoploss),
          trailing: values?.tredingstoploss === "true",
          take_profit: parseFloat(values?.takeprofit),
          orderQty: parseFloat(values?.orderqualityfix),
        },
        {
          headers: authToken,
        }
      );

      console.log(response?.data);
      setSuccess("Configration added successfully");
      setTimeout(() => {
        setSuccess(null);
        onHide();
        refetch();
      }, 3000);
    } catch (error) {
      console.error("Error adding client:", error);
      if (error?.response?.status === 400) {
        setError(error?.response?.data?.detail);
        setTimeout(() => {
          setError(null);
        }, 3000);
      } else {
        setError("An error occurred while adding the client.");
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
      setSuccess(null);
    }
    console.log(values, "Values");
  };

  return (
    <>
      <EditConfigrationModal />
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title className="clash-text">
            Add Strategy Configuration
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {error && (
            <div className="d-flex align-items-center error-message-container">
              <Info size={24} color="#ff0000" className="mx-1" />
              <p className="Plus-jakarta-text text-danger mb-0">{error}</p>
            </div>
          )}
          {success ? (
            <>
              <SuccessAnimation />
              <p className="clash-text d-flex justify-content-center">
                {success}
              </p>
            </>
          ) : (
            <Formik
              initialValues={{
                clientId: "",
                strategyname: "",
                stoploss: 0,
                tredingstoploss: "false",
                takeprofit: 0,
                orderqualityfix: 0,
              }}
              onSubmit={handleSubmit}
              validate={(values) => {
                const errors = {};
                if (!values.clientId) {
                  errors.clientId = "Client ID is required";
                }
                if (!values.strategyname) {
                  errors.strategyname = "Strategy is required";
                }
                if (
                  values.stoploss === null ||
                  values.stoploss === undefined ||
                  values.stoploss === ""
                ) {
                  errors.stoploss = "Stop loss is required";
                } else if (!values.stoploss) {
                  errors.stoploss = "Stop loss is required";
                } else if (isNaN(values.stoploss)) {
                  errors.stoploss = "Stop loss must be a number";
                } else if (parseFloat(values.stoploss) < 0) {
                  errors.stoploss = "Stop loss must be a non-negative number";
                }

                if (
                  values.takeprofit === null ||
                  values.takeprofit === undefined ||
                  values.takeprofit === ""
                ) {
                  errors.takeprofit = "Take profit is required";
                } else if (isNaN(values.takeprofit)) {
                  errors.takeprofit = "Take profit must be a number";
                } else if (parseFloat(values.takeprofit) < 0) {
                  errors.takeprofit =
                    "Take profit must be a non-negative number";
                }

                if (!values.orderqualityfix) {
                  errors.orderqualityfix = "Order Quantity Fix is required";
                } else if (!values.orderqualityfix) {
                  errors.orderqualityfix = "Order Quantity Fix is required";
                } else if (isNaN(values.orderqualityfix)) {
                  errors.orderqualityfix = "Order Quantity Fix be a number";
                } else if (parseFloat(values.orderqualityfix) < 1) {
                  errors.orderqualityfix = "Order Quantity minimum values is 1";
                }

                return errors;
              }}
            >
              {({ values, errors, handleChange, handleSubmit, isValid }) => (
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group controlId="clientId">
                        <Form.Label>Client Id</Form.Label>
                        <Form.Control
                          as="select"
                          name="clientId"
                          value={values?.clientId}
                          onChange={handleChange}
                          className="custom-input"
                          required
                        >
                          <option value="" disabled>
                            Select client
                          </option>
                          {clients.map((client) => (
                            <option
                              key={client?.clientId}
                              value={client?.clientId}
                            >
                              {client.clientId}
                            </option>
                          ))}
                        </Form.Control>
                        {errors.clientId && (
                          <p className="text-danger">{errors.clientId}</p>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="strategyname">
                        <Form.Label>Strategy</Form.Label>
                        <Form.Control
                          as="select"
                          name="strategyname"
                          value={values?.strategyname}
                          onChange={handleChange}
                          className="custom-input"
                          required
                        >
                          <option value="" disabled>
                            Select strategy
                          </option>
                          {strategy.map((strategyData) => (
                            <option
                              key={strategyData?.strategy}
                              value={strategyData?.strategy}
                            >
                              {strategyData?.strategy}
                            </option>
                          ))}
                        </Form.Control>
                        {errors.strategyname && (
                          <p className="text-danger">{errors.strategyname}</p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group controlId="stoploss">
                        <Form.Label>Stop loss %</Form.Label>
                        <Form.Control
                          type="text"
                          name="stoploss"
                          value={values?.stoploss}
                          onChange={handleChange}
                          className="custom-input"
                        ></Form.Control>
                        {errors.stoploss && (
                          <p className="text-danger">{errors.stoploss}</p>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="tredingstoploss">
                        <Form.Label>Trailing Stop loss</Form.Label>
                        <Form.Control
                          as="select"
                          name="tredingstoploss"
                          value={values?.tredingstoploss}
                          onChange={handleChange}
                          className="custom-input"
                          required
                        >
                          <option value="true">Enable</option>
                          <option value="false">Disable</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group controlId="takeprofit">
                    <Form.Label>Take Profit %</Form.Label>
                    <Form.Control
                      type="string"
                      name="takeprofit"
                      value={values?.takeprofit}
                      onChange={handleChange}
                      className="custom-input"
                      required
                    ></Form.Control>
                    {errors.takeprofit && (
                      <p className="text-danger">{errors.takeprofit}</p>
                    )}
                  </Form.Group>
                  <Form.Group controlId="orderqualityfix">
                    <Form.Label>Order Quantity Fix</Form.Label>
                    <Form.Control
                      type="text"
                      name="orderqualityfix"
                      value={values?.orderqualityfix}
                      onChange={handleChange}
                      className="custom-input"
                      required
                    ></Form.Control>
                    {errors.orderqualityfix && (
                      <p className="text-danger">{errors.orderqualityfix}</p>
                    )}
                  </Form.Group>
                  <div className="d-flex justify-content-between mt-3">
                    <Button className="py-2 cancle-button" onClick={onHide}>
                      Cancel
                    </Button>
                    <Button
                      variant="secondary"
                      type="submit"
                      className="px-4 py-2"
                      disabled={!isValid}
                    >
                      Add
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddConfigration;
