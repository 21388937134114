import React from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";

const EditStrategyModal = ({
  show,
  onHide,
  editData,
  setEditData,
  handleUpdateStrategy,
}) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Edit Strategy</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formStrategy">
            <Form.Label>Strategy</Form.Label>
            <Form.Control
              type="text"
              className="custom-input"
              value={editData?.strategy}
              disabled
            />
          </Form.Group>
          <Form.Group controlId="symbol">
            <Form.Label>Symbol</Form.Label>
            <Form.Control
              type="text"
              className="custom-input"
              value={editData?.symbol}
              onChange={(e) =>
                setEditData({ ...editData, symbol: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group controlId="orderType">
            <Form.Label>Order Type</Form.Label>
            <Form.Control
              as="select"
              type="text"
              className="custom-input"
              value={editData?.orderType}
              onChange={(e) =>
                setEditData({ ...editData, orderType: e.target.value })
              }
            >
              <option value="Limit">Limit</option>
              <option value="Market">Market</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="direction">
            <Form.Label>Direction</Form.Label>
            <div className="d-flex justify-content-between">
              <Form.Check
                type="radio"
                label="Long / Short"
                name="direction"
                id="edit_long_short"
                value="long_short"
                checked={editData.direction === "long_short"}
                onChange={(e) =>
                  setEditData({ ...editData, direction: e.target.value })
                }
                className="custom-radio"
              />
              <Form.Check
                type="radio"
                label="Long / Only"
                name="direction"
                id="edit_long_only"
                value="long_only"
                checked={editData.direction === "long_only"}
                onChange={(e) =>
                  setEditData({ ...editData, direction: e.target.value })
                }
                className="custom-radio"
              />
              <Form.Check
                type="radio"
                label="Short / Only"
                name="direction"
                id="edit_short_only"
                value="short_only"
                checked={editData.direction === "short_only"}
                onChange={(e) =>
                  setEditData({ ...editData, direction: e.target.value })
                }
                className="custom-radio"
              />
            </div>
          </Form.Group>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="inverse">
                <Form.Label>Inverse</Form.Label>
                <Form.Control
                  as="select"
                  className="custom-input"
                  value={editData.inverse.toString()}
                  onChange={(e) =>
                    setEditData({
                      ...editData,
                      inverse: e.target.value === "true",
                    })
                  }
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="pyramid">
                <Form.Label>Pyramid</Form.Label>
                <Form.Control
                  as="select"
                  className="custom-input"
                  value={editData?.pyramid.toString()}
                  onChange={(e) =>
                    setEditData({
                      ...editData,
                      pyramid: e.target.value === "true",
                    })
                  }
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <div className="d-flex justify-content-between mt-3">
          <Button className="py-2 cancle-button" onClick={() => onHide()}>
            Close
          </Button>
          <Button
            className="px-3 py-2 save-change-button"
            variant="secondary"
            onClick={handleUpdateStrategy}
          >
            Save Changes
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditStrategyModal;
