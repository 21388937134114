import React, { useState, useEffect, useContext } from "react";
import { Container, Table, Pagination, Button, Modal } from "react-bootstrap";
import axios from "axios";
import EditStrategyModal from "../Modals/EditModals/EditStrategy";
import "./Table.css";
import deleteicon from "../../../Assets/Delete_icon.png";
import { Trash, PencilSimple } from "@phosphor-icons/react";
import {
  UPDATE_STRATEGY_DETAILS,
  DELETE_STRATEGY_DETAILS,
  authToken,
} from "../../../Constant/Constants";
import { StrategyContext } from "../../../Context/StrategyContext";
import { StrategyConfigContext } from "../../../Context/StrategyConfigContext";

const Strategy = () => {
  const {
    strategy,
    currentPage,
    totalPages,
    changePage,
    isLoading,
    isError,
    refetch,
  } = useContext(StrategyContext);
  const { refetch: refetchStrategyConfig } = useContext(StrategyConfigContext);

  const [deleteStatus, setDeleteStatus] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editData, setEditData] = useState({
    strategy: "",
    symbol: "",
    orderType: "",
    direction: "",
    inverse: false,
    pyramid: false,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [strategyToDelete, setStrategyToDelete] = useState(null);
  const [refetchData, setRefetchData] = useState(false);

  useEffect(() => {
    if (deleteStatus !== null) {
      setRefetchData(false);
    }
  }, [deleteStatus, refetchData]);

  const handleDeleteStrategy = async (strategy) => {
    try {
      await axios.delete(`${DELETE_STRATEGY_DETAILS}/${strategy}`, {
        headers: authToken,
      });

      setDeleteStatus(true);
      setStrategyToDelete(null);
      setShowDeleteModal(false);
      refetch();
      refetchStrategyConfig();

      setTimeout(() => {
        setShowDeleteModal(false);
      }, 1000);
    } catch (error) {
      console.error("Error deleting strategy:", error);
      setDeleteStatus(false);
    }
  };

  const handleEditStrategy = (rowData) => {
    setEditData(rowData);
    setShowEditModal(true);
  };

  const handleUpdateStrategy = async () => {
    try {
      await axios.put(
        `${UPDATE_STRATEGY_DETAILS}/${editData?.strategy}`,
        editData,
        {
          headers: authToken,
        }
      );
      setShowEditModal(false);
      refetch();
    } catch (error) {
      console.error("Error updating strategy:", error);
    }
  };

  const handleDeleteConfirmation = (strategy) => {
    setStrategyToDelete(strategy);
    setShowDeleteModal(true);
  };

  return (
    <div>
      <Container fluid>
        <Container fluid>
          <Table responsive className="table-responsive">
            <thead className="table-dark">
              <tr>
                <th>Strategy Name</th>
                <th>Symbol</th>
                <th>Order Type</th>
                <th>Direction</th>
                <th>Inverse</th>
                <th>Pyramid</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="custom-table-body">
              {isLoading ? (
                <tr>
                  <td colSpan="4">Loading...</td>
                </tr>
              ) : isError ? (
                <tr>
                  <td colSpan="4">Error while fetching data</td>
                </tr>
              ) : Array.isArray(strategy) && strategy.length > 0 ? (
                strategy.map((row) => (
                  <tr key={row?.strategy}>
                    <td>{row?.strategy}</td>
                    <td>{row?.symbol}</td>
                    <td>{row?.orderType}</td>
                    <td>{row?.direction.replace(/_/g, "/")}</td>
                    <td>{row?.inverse ? "True" : "False"}</td>
                    <td>{row?.pyramid ? "True" : "False"}</td>
                    <td>
                      <div className="d-flex flex-column flex-md-row align-items-md-center">
                        <Button
                          className="edit-button pt-1 me-md-2 mb-1 mb-md-0"
                          onClick={() => handleEditStrategy(row)}
                        >
                          <PencilSimple size={20} color="#faf9fb" />
                        </Button>
                        <Button
                          className="delete-button pt-1"
                          onClick={() =>
                            handleDeleteConfirmation(row?.strategy)
                          }
                        >
                          <Trash size={20} color="#faf9fb" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No data available</td>
                </tr>
              )}
            </tbody>
          </Table>
          <Pagination className="d-flex justify-content-end custom-pagination">
            <Pagination.Prev
              onClick={() => changePage(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => changePage(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => changePage(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </Container>
      </Container>

      {/* Edit Modal */}
      <EditStrategyModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        editData={editData}
        setEditData={setEditData}
        handleUpdateStrategy={handleUpdateStrategy}
      />

      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header className="d-flex justify-content-center">
          <img src={deleteicon} alt="delete_confirmation"></img>
        </Modal.Header>
        <Modal.Body className="clash-text d-flex justify-content-center">
          Are you sure you want to Delete?
        </Modal.Body>
        <div className="d-flex justify-content-evenly mt-3 mb-4">
          <Button
            className="py-2 cancle-button"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="px-3 py-2"
            variant="secondary"
            onClick={() => handleDeleteStrategy(strategyToDelete)}
          >
            Delete
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Strategy;
