import React, { createContext, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { GET_STRATEGY_DETAILS, authToken } from "../Constant/Constants";

// Create Context
export const StrategyContext = createContext();

// Provider Component
export const StrategyProvider = ({ children }) => {
  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const fetchStrategy = async (page = 1) => {
    const { data } = await axios.get(`${GET_STRATEGY_DETAILS}`, {
      headers: {
        apiKey: authToken.apiKey,
      },
    });
    return {
      data: data.slice((page - 1) * rowsPerPage, page * rowsPerPage),
      total: data.length,
    };
  };

  const {
    data: strategyData,
    isLoading,
    isError,
    error,
    refetch
  } = useQuery(["strategy", currentPage], () => fetchStrategy(currentPage), {
    keepPreviousData: true,
  });

  const totalPages = strategyData
    ? Math.ceil(strategyData.total / rowsPerPage)
    : 0;

  const changePage = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <StrategyContext.Provider
      value={{
        strategy: strategyData?.data || [],
        isLoading,
        totalRows: strategyData?.total || 0,
        currentPage,
        totalPages,
        changePage,
        isError,
        error,
        fetchStrategy,
        refetch
      }}
    >
      {children}
    </StrategyContext.Provider>
  );
};
