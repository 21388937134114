import React, { useState, useContext } from "react";
import { Container, Table, Pagination, Button, Modal } from "react-bootstrap";
import axios from "axios";
import deleteicon from "../../../Assets/Delete_icon.png";
import "./Table.css";
import { Trash, PencilSimple } from "@phosphor-icons/react";
import EditConfigrationModal from "../Modals/EditModals/EditConfigration";
import {
  UPDATE_CONFIGURATION_DETAILS,
  DELETE_CONFIGURATION_DETAILS,
  authToken,
} from "../../../Constant/Constants";
import { StrategyConfigContext } from "../../../Context/StrategyConfigContext";
const Configration = () => {
  const { configs, changePage, refetch, currentPage, totalPages } = useContext(
    StrategyConfigContext
  );
  const [deleteStatus, setDeleteStatus] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [configrationToDelete, setConfigrationToDelete] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const [editData, setEditData] = useState({
    clientId: "",
    strategy: "",
    orderQty: true,
    trailing: 0,
    stopLoss: 0,
  });

  const handleDeleteConfirmation = (clientId, strategy) => {
    setConfigrationToDelete({ clientId, strategy });
    setShowDeleteModal(true);
  };

  const handleDeleteConfigration = async (clientId) => {
    try {
      await axios.delete(
        `${DELETE_CONFIGURATION_DETAILS}/${clientId?.strategy}/${clientId?.clientId}`,
        {
          headers: authToken,
        }
      );
      setDeleteStatus(true);
      setConfigrationToDelete(null);
      setDeleteConfirmation(false);
      refetch();
      setTimeout(() => {
        setShowDeleteModal(false);
      }, 1000);
    } catch (error) {
      console.error("Error deleting data:", error);
      setDeleteStatus(false);
    }
  };

  const handleEditClient = (rowData) => {
    setEditData(rowData);
    setShowEditModal(true);
  };

  const handleUpdateClient = async (clientId, strategy) => {
    try {
      await axios.put(
        `${UPDATE_CONFIGURATION_DETAILS}/${strategy}/${clientId}`,
        editData,
        {
          headers: authToken,
        }
      );
      setShowEditModal(false);
      refetch();
    } catch (error) {
      console.error("Error updating client:", error);
    }
  };

  return (
    <>
      <Container fluid>
        <Container fluid>
          <Table responsive className="table-responsive">
            <thead className="table-dark">
              <tr>
                <th>Client Id</th>
                <th>Strategy</th>
                <th>Take Profit (%)</th>
                <th>Order Quantity</th>
                <th>Trailing SL</th>
                <th>SL (%)</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="custom-table-body">
              {Array.isArray(configs) &&
                configs.map((row) => (
                  <tr key={row?.clientId}>
                    <td>{row?.clientId}</td>
                    <td>{row?.strategy}</td>
                    <td>{row?.take_profit}</td>
                    <td>{row?.orderQty}</td>
                    <td>{row?.trailing ? "True" : "False"}</td>
                    <td>{row?.stopLoss}</td>
                    <td>
                    <div className="d-flex flex-column flex-md-row align-items-md-center">
                      <Button
                        className="edit-button pt-1 me-md-2 mb-1 mb-md-0"
                        onClick={() => handleEditClient(row)}
                      >
                        <PencilSimple size={20} color="#faf9fb" />
                      </Button>
                      <Button
                        className="delete-button pt-1"
                        onClick={() =>
                          handleDeleteConfirmation(row?.clientId, row?.strategy)
                        }
                      >
                        <Trash size={20} color="#faf9fb" />
                      </Button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Pagination className="d-flex justify-content-end custom-pagination">
            <Pagination.Prev
              onClick={() => changePage(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => changePage(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => changePage(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </Container>
      </Container>

      {/* Edit Modal */}
      <EditConfigrationModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        editData={editData}
        setEditData={setEditData}
        handleUpdateClient={handleUpdateClient}
      />

      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header className="d-flex justify-content-center">
          <img src={deleteicon} alt="delete_confirmation"></img>
        </Modal.Header>
        <Modal.Body className="clash-text d-flex justify-content-center">
          Are you sure you want to Delete?
        </Modal.Body>
        <div className="d-flex justify-content-evenly mt-3 mb-4">
          <Button
            className="py-2 cancle-button"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="px-3 py-2"
            variant="secondary"
            onClick={() => handleDeleteConfigration(configrationToDelete)}
          >
            Delete
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Configration;
