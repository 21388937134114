import React, { createContext } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { GET_CLIENT_DETAILS, authToken } from "../Constant/Constants";

export const ClientContext = createContext();

export const ClientProvider = ({ children }) => {
  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(1);

  const fetchClients = async (page = 1) => {
    const { data } = await axios.get(`${GET_CLIENT_DETAILS}`, {
      headers: {
        apiKey: authToken.apiKey,
      },
    });
    return {
      data: data.slice((page - 1) * rowsPerPage, page * rowsPerPage),
      total: data.length,
    };
  };

  const {
    data: clientData,
    isLoading,
    isError,
    error,
    refetch
  } = useQuery(["clients", currentPage], () => fetchClients(currentPage), {
    keepPreviousData: true,
  });

  const totalPages = clientData ? Math.ceil(clientData.total / rowsPerPage) : 0;

  const changePage = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <ClientContext.Provider
      value={{
        clients: clientData?.data || [],
        isLoading,
        totalRows: clientData?.total || 0,
        currentPage,
        totalPages,
        changePage,
        isError,
        error,
        fetchClients,
        refetch
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};
