import React, { useState } from "react";
import { Container, Table, Pagination } from "react-bootstrap";
import { useQuery } from "react-query";
import axios from "axios";
import "./Table.css";
import { GET_ORDERLOGS_DETAILS, authToken } from "../../../Constant/Constants";

const OrderLogs = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { data, isLoading, isError } = useQuery(
    ["orderLogs", currentPage, rowsPerPage],
    async () => {
      const response = await axios.get(`${GET_ORDERLOGS_DETAILS}`, {
        headers: authToken,
      });
      return response.data;
    }
  );

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const rows = data?.slice(startIndex, endIndex) || [];

  const totalPages = Math.ceil((data?.length || 0) / rowsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) {
      return;
    }
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  return (
    <Container fluid>
      <Container fluid>
        <Table responsive className="table-responsive">
          <thead className="table-dark">
            <tr>
              <th>Client Id</th>
              <th>Strategy</th>
              <th>Lot Size</th>
              <th>Symbol</th>
              <th>Time (EST - 1)</th>
              <th>Side</th>
              <th>Order Type</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody className="custom-table-body">
            {isLoading ? (
              <tr>
                <td colSpan="8">Loading...</td>
              </tr>
            ) : isError ? (
              <tr>
                <td colSpan="8">Error while fetching data</td>
              </tr>
            ) : rows && rows.length > 0 ? (
              rows.map((row, index) => (
                <tr key={index}>
                  <td>{row?.user_order_data?.clientId}</td>
                  <td>{row?.strategy}</td>
                  <td>{row?.user_order_data?.quantity}</td>
                  <td>{row?.user_order_data?.symbol}</td>
                  <td>{row?.timestamp.replace(/\sCDT-\d{4}/, "")}</td>
                  <td
                    className={
                      row?.user_order_data?.action === "sell"
                        ? "sell-action"
                        : "buy-action"
                    }
                  >
                    {row?.user_order_data?.action.charAt(0).toUpperCase() +
                      row?.user_order_data?.action.slice(1)}
                  </td>
                  <td>{row?.user_order_data?.orderType}</td>
                  <td>
                    {row?.order_details?.PositionBase?.OpenPrice
                      ? row?.order_details?.PositionBase?.OpenPrice
                      : "-"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">No data available</td>
              </tr>
            )}
          </tbody>
        </Table>

        <div className="d-flex justify-content-end align-items-center">
          <div>
            <span className="ml-2 Plus-jakarta-text">Rows per page :</span>
            <select
              className="mx-2"
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
            >
              {[10, 20, 30, 40, 50].map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div>
            <Pagination className="mt-3">
              <Pagination.Item>
                {data &&
                  `${startIndex + 1}-${Math.min(endIndex, data.length)} of ${
                    data.length
                  }`}
              </Pagination.Item>
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default OrderLogs;
