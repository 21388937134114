import React from "react";
import { Link } from "react-router-dom";
import "../Pages.css";

const NotFound = () => {
  return (
    <div className="not-found">
      <div className="not-found-content">
        <h1 className="error-code">404</h1>
        <h2 className="error-message">Oops! Page not found</h2>
        <p className="error-small-text">Sorry, the page you are looking for could not be found.</p>
        <p className="error-small-text">
          Return to{" "}
          <Link to="/" className="back-link">
            Login page
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default NotFound;
