export const BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://46.101.88.38";

// Authorization token
export const authToken = {
  Authorization: "e8fb72f3441a0d8e5c836dc8c9a42bb4",
};

// POST : Add login data
export const ADD_LOGIN_DETAILS = process.env.REACT_APP_API_VALIDATE_BASE_URL || `http://159.203.84.231:80/validate`;
// Client Tab

// POST : Add new client
export const ADD_CLIENT_DETAILS = `${BASE_URL}/add_client`;
// GET: Get client details
export const GET_CLIENT_DETAILS = `${BASE_URL}/get_client_details`;
// PUT : Update client details
export const UPDATE_CLIENT_DETAILS = `${BASE_URL}/update_client`;
// DELETE : Delete client details
export const DELETE_CLIENT_DETAILS = `${BASE_URL}/delete_client`;

// Strategy Tab

// POST : Add new strategy
export const ADD_STRATEGY_DETAILS = `${BASE_URL}/add_strategy`;
// GET: Get client details
export const GET_STRATEGY_DETAILS = `${BASE_URL}/get_strategy_details`;
// PUT : Update client details
export const UPDATE_STRATEGY_DETAILS = `${BASE_URL}/update_strategy`;
// DELETE : Delete client details
export const DELETE_STRATEGY_DETAILS = `${BASE_URL}/delete_strategy`;

// Configuration Tab

// POST : Add new Configuration
export const ADD_CONFIGURATION_DETAILS = `${BASE_URL}/add_strategy_config`;
// GET: Get client details
export const GET_CONFIGURATION_DETAILS = `${BASE_URL}/get_strategy_config_details`;
// PUT : Update client details
export const UPDATE_CONFIGURATION_DETAILS = `${BASE_URL}/update_strategy_config`;
// DELETE : Delete client details
export const DELETE_CONFIGURATION_DETAILS = `${BASE_URL}/delete_strategy_config`;

// Webhook Logs
export const GET_WEBHOOKSLOGS_DETAILS = `${BASE_URL}/get_webhook_logs`;

// Order Logs
export const GET_ORDERLOGS_DETAILS = `${BASE_URL}/get_order_logs`;
