import React, { useContext } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { StrategyContext } from "../../../../Context/StrategyContext";
import { ClientContext } from "../../../../Context/ClientContext";

const EditConfigrationModal = ({
  show,
  onHide,
  editData,
  setEditData,
  handleUpdateClient,
}) => {

  const { clients } = useContext(ClientContext);
  const { strategy } = useContext(StrategyContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Edit Configuration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formClientId">
                  <Form.Label>Client Id</Form.Label>
                  <Form.Control
                    as="select"
                    className="custom-input"
                    name="clientId"
                    value={editData?.clientId}
                    onChange={handleInputChange}
                    disabled
                  >
                    <option value="" disabled>
                      Select client
                    </option>
                    {clients.map((client) => (
                      <option key={client?.clientId} value={client?.clientId}>
                        {client?.clientId}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formStrategy">
                  <Form.Label>Strategy</Form.Label>
                  <Form.Control
                    as="select"
                    className="custom-input"
                    name="strategy"
                    value={editData?.strategy}
                    onChange={handleInputChange}
                    disabled
                  >
                    <option value="" disabled>
                      Select strategy
                    </option>
                    {strategy.map((strategy) => (
                      <option key={strategy.strategy} value={strategy.strategy}>
                        {strategy.strategy}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formStopLoss">
                  <Form.Label>Stop Loss (%)</Form.Label>
                  <Form.Control
                    type="text"
                    className="custom-input"
                    name="stopLoss"
                    value={editData?.stopLoss}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="trailingOptions">
                  <Form.Label>Trailing Stop loss</Form.Label>
                  <Form.Control
                    as="select"
                    className="custom-input"
                    name="trailingOptions"
                    value={editData?.trailing?.toString()}
                    onChange={(e) =>
                      setEditData({
                        ...editData,
                        trailing: e.target.value === "true",
                      })
                    }
                  >
                    <option value="true">Enable</option>
                    <option value="false">Disable</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="formTakeProfit">
              <Form.Label>Take Profit (%)</Form.Label>
              <Form.Control
                type="text"
                className="custom-input"
                name="take_profit"
                value={editData?.take_profit}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formOrderQty">
              <Form.Label>Order Quantity Fix</Form.Label>
              <Form.Control
                type="text"
                className="custom-input"
                name="orderQty"
                value={editData?.orderQty}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
          <div className="d-flex justify-content-between mt-3">
            <Button className="py-2 cancle-button" onClick={onHide}>
              Close
            </Button>
            <Button
              className="px-3 py-2 save-change-button"
              variant="secondary"
              onClick={() =>
                handleUpdateClient(editData?.clientId, editData?.strategy)
              }
            >
              Save Changes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditConfigrationModal;
