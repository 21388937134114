import React, { createContext } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { GET_CONFIGURATION_DETAILS, authToken } from "../Constant/Constants";

export const StrategyConfigContext = createContext();

export const StrategyConfigProvider = ({ children }) => {
  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(1);

  const fetchStrategyConfig = async (page = 1) => {
    const { data } = await axios.get(`${GET_CONFIGURATION_DETAILS}`, {
      headers: {
        apiKey: authToken.apiKey,
      },
    });
    return {
      data: data.slice((page - 1) * rowsPerPage, page * rowsPerPage),
      total: data.length,
    };
  };

  const {
    data: strategyConfigData,
    isLoading,
    isError,
    error,
    refetch
  } = useQuery(["configuration", currentPage], () => fetchStrategyConfig(currentPage), {
    keepPreviousData: true,
  });

  const totalPages = strategyConfigData ? Math.ceil(strategyConfigData.total / rowsPerPage) : 0;

  const changePage = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <StrategyConfigContext.Provider
      value={{
        configs: strategyConfigData?.data || [],
        isLoading,
        totalRows: strategyConfigData?.total || 0,
        currentPage,
        totalPages,
        changePage,
        isError,
        error,
        fetchStrategyConfig,
        refetch
      }}
    >
      {children}
    </StrategyConfigContext.Provider>
  );
};
