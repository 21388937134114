import React, { useState, useContext } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import axios from "axios";
import "./Modal.css";
import SuccessAnimation from "../CommonComponents/SuccessAnimation";
import { ADD_CLIENT_DETAILS, authToken } from "../../../Constant/Constants";
import { Info } from "@phosphor-icons/react";
import { ClientContext } from "../../../Context/ClientContext";

const AddClient = ({ show, onHide }) => {
  const { refetch } = useContext(ClientContext);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios.post(
        `${ADD_CLIENT_DETAILS}`,
        {
          clientId: values?.clientid,
          token: values.token,
          acc_key: values?.acckey,
          tradeable: values?.tradeable === "true",
          live: values?.accountType === "true",
        },
        {
          headers: authToken,
        }
      );
      console.log(response?.data);
      setSuccess("Client added successfully");
      setTimeout(() => {
        setSuccess(null);
        onHide();
        refetch();
      }, 3000);
    } catch (error) {
      console.error("Error adding client:", error);
      if (error?.response) {
        if (error?.response?.status === 400) {
          setError(error?.response?.data?.detail);
        } else if (error?.response?.status === 402) {
          setError(error?.response?.data?.detail);
        }
        setTimeout(() => {
          setError(null);
        }, 3000);
      }

      console.log(error?.response?.data?.detail, "error");
    } finally {
      setSubmitting(false);
    }
    console.log(values, "Values");
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title className="clash-text">Add Tradovate client</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {error && (
            <div className="d-flex align-items-center error-message-container">
              <Info size={24} color="#ff0000" className="mx-1" />
              <p className="Plus-jakarta-text text-danger mb-0">{error}</p>
            </div>
          )}

          {success ? (
            <>
              <SuccessAnimation />
              <p className="clash-text d-flex justify-content-center">
                {success}
              </p>
            </>
          ) : (
            <Formik
              initialValues={{
                clientid: "",
                token: "",
                acckey: "",
                tradeable: "true",
                accountType: "true",
              }}
              onSubmit={handleSubmit}
              validate={(values) => {
                const errors = {};
                if (!values?.clientid) {
                  errors.clientid = "Client Id is required";
                }
                if (!values?.token) {
                  errors.token = "Token is required";
                }
                if (!values?.acckey) {
                  errors.acckey = "Account Key is required";
                }
                if (!values?.tradeable) {
                  errors.tradeable = "Tradeable is required";
                }
                if (!values?.accountType) {
                  errors.accountType = "AccountType is required";
                }
                return errors;
              }}
            >
              {({ values, errors, handleChange, handleSubmit, isValid }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="clientid">
                    <Form.Label>Client Id</Form.Label>
                    <Form.Control
                      type="text"
                      name="clientid"
                      value={(values?.clientid || "").replace(/\s/g, "")}
                      onChange={handleChange}
                      className="custom-input"
                      required
                    />
                    {errors.clientid && (
                      <p className="text-danger">{errors.clientid}</p>
                    )}
                  </Form.Group>

                  <Form.Group controlId="token">
                    <Form.Label>Token</Form.Label>
                    <Form.Control
                      type="text"
                      name="token"
                      value={values?.token}
                      onChange={handleChange}
                      className="custom-input"
                      required
                    />
                    {errors.token && (
                      <p className="text-danger">{errors.token}</p>
                    )}
                  </Form.Group>

                  <Form.Group controlId="sec">
                    <Form.Label>Account Key</Form.Label>
                    <Form.Control
                      type="text"
                      name="acckey"
                      value={values?.acckey}
                      onChange={handleChange}
                      className="custom-input"
                      required
                    />
                    {errors.acckey && (
                      <p className="text-danger">{errors.acckey}</p>
                    )}
                  </Form.Group>

                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group controlId="tradeable">
                        <Form.Label>Tradeable</Form.Label>
                        <Form.Control
                          as="select"
                          name="tradeable"
                          value={values.tradeable}
                          onChange={handleChange}
                          className="custom-input"
                          required
                        >
                          <option value="true">Enable</option>
                          <option value="false">Disable</option>
                        </Form.Control>
                        {errors.tradeable && (
                          <p className="text-danger">{errors.tradeable}</p>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="accountType">
                        <Form.Label>Account Type</Form.Label>
                        <Form.Control
                          as="select"
                          name="accountType"
                          value={values.accountType}
                          onChange={handleChange}
                          className="custom-input custom-dropdown"
                          required
                        >
                          <option value="true">Live</option>
                          <option value="false">Demo</option>
                        </Form.Control>
                        {errors.accountType && (
                          <p className="text-danger">{errors.accountType}</p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="d-flex justify-content-between">
                    <Button className="py-2 cancle-button" onClick={onHide}>
                      Cancel
                    </Button>
                    <Button
                      variant="secondary"
                      type="submit"
                      className="px-4 py-2"
                      disabled={!isValid}
                    >
                      Add User
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddClient;
